.fade-in {
  opacity: 1;
  animation-name: FadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.8s;
}

@keyframes FadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-in-bottom {
  opacity: 1;
  animation-name: FadeInBottom;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.6s;
}

@keyframes FadeInBottom {
  0% {
    opacity: 0;
    transform: translateY(+20%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
